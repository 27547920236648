import { render, staticRenderFns } from "./why.vue?vue&type=template&id=364d0f08"
import script from "./why.vue?vue&type=script&lang=js"
export * from "./why.vue?vue&type=script&lang=js"
import style0 from "./why.vue?vue&type=style&index=0&id=364d0f08&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports