<template>
    <div class="why_box">
        <div class="why_icon">
            <img src="../../assets/images/logo_slogan_.png" alt="">
        </div>
        <div class="why_title">
            Why Contrimetric?
        </div>
        <div class="why_text">
            Choosing Contrimetric means choosing a partner dedicated to precision and results. Our team is composed of
            seasoned experts in data science, analytics, and strategic consulting, all working in harmony to provide
            unmatched service and solutions. We invite you to discover how our approach to data can transform your
            business outcomes and empower decision-makers at every level.
        </div>
        <div class="why_btn">
            <el-button type="primary" @click="openApply">Apply Membership</el-button>
            <el-button type="default" @click="goToRequestDemo">Request a Demo</el-button>
        </div>

        <FourAdv></FourAdv>
        <applyMembershipDialog ref="applyMembershipDialogRef"></applyMembershipDialog>
    </div>
</template>

<script>
import FourAdv from "@/components/fourAdv/index.vue"
import applyMembershipDialog from "@/views/aboutNew/applyMembershipDialog.vue";

export default {
    components: {
        FourAdv,
        applyMembershipDialog
    },
    data() {
        return {

        }
    },
    methods: {
        goToRequestDemo() {
            this.$router.push({ name: 'requestDemo' });
            window.scrollTo(0, 0)
        },
        openApply(){
            this.$refs.applyMembershipDialogRef.openDialog()
        }
    }
}
</script>

<style lang='scss'>
.why_box {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .why_icon,
    .why_title,
    .why_text,
    .why_btn {
        text-align: center;
    }

    .why_icon {
        img {
            width: 400px;
            margin-bottom: 20px;
        }
    }

    .why_title {
        font-size: 30px;
        font-weight: bold;
        color: black;
        margin-top: 20px;
    }

    .why_text {
        font-size: 16px;
        margin: 50px 0;
        max-width: 1100px;
        line-height: 1.5;
        text-align: justify;
    }
}

@media (max-width: 768px) {
    .why_box {
        margin-top: 30px;

        .why_title {
            font-size: 24px;
        }
    }
}
</style>