<template>
    <div class="why_list">
        <div><i class="el-icon-check"></i> No setup fee</div>
        <div><i class="el-icon-check"></i> Apply plugin code free of charge</div>
        <div><i class="el-icon-check"></i> Customer service 24/7</div>
        <div><i class="el-icon-check"></i> Cancel any time</div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style lang='scss'>
.why_list {
    text-align: center;
    margin-top: 40px;
    display: flex;
    font-size: 16px;
    color: #65676b;

    div {
        margin: 0 10px;
    }

    i {
        background-color: #e1f8fa;
        padding: 4px;
        border-radius: 50%;
        color: #1da6b8;
    }
}

@media (max-width: 768px) {
    .why_list {
        flex-wrap: wrap;

        div {
            flex: 0 0 43%;
            margin-bottom: 10px;
        }
    }
}
</style>